//

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { MainLayout, SubpageHeader, createMetaData } from '../components';

const NotFoundPage = () => {
	const { pageError404Json: json } = useStaticQuery(graphql`
		query {
			pageError404Json {
				title
				subtitle
				permalink
				meta_title
				meta_keywords
				meta_description
			}
		}
	`);
	const { site, page, seo } = createMetaData(json);
	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
		</MainLayout>
	);
};

export default NotFoundPage;

/*
const NotFoundPage = () => (
	<>
		<h1>NOT FOUND</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</>
);

export default NotFoundPage;
*/
